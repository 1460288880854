*, p, ul {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: Manrope, sans-serif;
  outline: none
}
body {
  overflow-x: hidden;
}

a {
  cursor: pointer
}

a, a:hover {
  color: inherit;
  text-decoration: none
}

.text-simple {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px
}

.text-title-st {
  font-size: 42px;
  line-height: 1;
  letter-spacing: .004em
}

.text-title-nd, .text-title-st {
  font-style: normal;
  font-weight: 800
}

.text-title-nd {
  font-size: 32px;
  line-height: 1.2
}

.text-title-rd {
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 1.2
}

section {
  overflow: hidden
}

.container {
  max-width: 1160px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px
}

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

input[type=number] {
  -moz-appearance: textfield
}

.btn-default {
  padding: 15px 50px;
  letter-spacing: .004em;
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  color: #fff;
  background: #1a279a;
  -webkit-border-radius: 22.5px;
  -moz-border-radius: 22.5px;
  border-radius: 22.5px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.btn-default:hover {
  opacity: .7;
  color: #fff
}
.btn-default {
  height: 53px!important;
}
.btn-defaultsqr {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 50px;
  height: 53px!important;
  max-height: 53px;
  background: #1a279a;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  color: #fff;
  letter-spacing: .004em;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px
}
button:active {
  transform: scale(0.9)!important;
}

.btn-defaultsqr:hover {
  opacity: .7;
  color: #fff
}

header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

header, header .navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

header .navigation {
  height: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 1
}

header .navigation .logo {
  width: 20%
}

header .navigation .logo .mobile {
  display: none
}

header .navigation .links ul {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

header .navigation .links ul, header .navigation .links ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

header .navigation .links ul li {
  margin: 0 25px;
  width: 200px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

header .navigation .links ul li, header .navigation .links ul li a {
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

header .navigation .links ul li a {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: .004em;
  color: #fff;
  position: relative
}

header .navigation .links ul li a:after {
  position: absolute;
  content: "";
  bottom: -5px;
  right: 0;
  width: 20px;
  height: 2px;
  background: #fff;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

header .navigation .links ul li a:hover:after {
  width: 100%
}

header .navigation .links ul li:nth-child(2) {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

header .navigation .links ul li:nth-child(2) a {
  color: rgba(30, 37, 50, .6)
}

header .navigation .links ul li:nth-child(2) a:after {
  right: unset;
  left: 0;
  background: rgba(30, 37, 50, .6)
}

header .navigation .additionals {
  width: 20%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

header .navigation .additionals, header .navigation .additionals .lang {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

header .navigation .additionals .lang {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-right: 15px
}

header .navigation  .navigation-lang {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #000;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  margin: 0 7.5px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  cursor:pointer
}
header .navigation  .navigation-lang.current {
  background-color: #fff;
  border: 1px solid #000;
}

header .navigation .additionals .lang a.current {
  border: 1px solid #000;
  background: #fff
}

header .navigation .additionals .lang a:hover {
  opacity: .7
}

header .navigation .mobile-buttons {
  display: none
}

header.bg-white {
  background: #9e9e9eb3!important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px)
}

header.bg-white .links ul li a {
  color: #fff !important
}

header.bg-white .links ul li a:after {
  background: #fff !important
}

header.not-index {
  background: #fff
}

header.not-index:after {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: -webkit-gradient(linear, left top, left bottom, from(#5b5b5b), to(#121429));
  background: -webkit-linear-gradient(top, #5b5b5b, #121429);
  background: -moz-linear-gradient(top, #5b5b5b 0, #121429 100%);
  background: -o-linear-gradient(top, #5b5b5b 0, #121429 100%);
  background: linear-gradient(180deg, #5b5b5b, #121429);
  content: ""
}

header.not-index .links ul li:nth-child(2) a {
  color: rgba(30, 37, 50, .6) !important
}

header.not-index .links ul li:nth-child(2) a:after {
  right: unset;
  left: 0;
  background: rgba(30, 37, 50, .6) !important
}

.introduction {
  position: relative;
  padding-top: 150px;
  overflow: visible
}

.introduction:before {
  z-index: 0;
  left: 0;
  height: 800px;
  background: -webkit-gradient(linear, left top, left bottom, from(#5b5b5b), to(#121429));
  background: -webkit-linear-gradient(top, #5b5b5b, #121429);
  background: -moz-linear-gradient(top, #5b5b5b 0, #121429 100%);
  background: -o-linear-gradient(top, #5b5b5b 0, #121429 100%);
  background: linear-gradient(180deg, #5b5b5b, #121429)
}

.introduction:after, .introduction:before {
  position: absolute;
  top: 0;
  right: 0;
  content: ""
}

.introduction:after {
  z-index: 2;
  bottom: 0;
  width: 50%;
  -webkit-box-shadow: -20px 24px 184px hsla(0, 0%, 44%, .33);
  -moz-box-shadow: -20px 24px 184px hsla(0, 0%, 44%, .33);
  box-shadow: -20px 24px 184px hsla(0, 0%, 44%, .33);
  background: #fff
}

.introduction .content-backdrop {
  z-index: 1
}

.introduction .content-backdrop .container .content-back {
  position: relative;
  width: 100%
}

.introduction .content-backdrop .container .content-back .introvideo-modal {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 15px;
  width: 250px;
  height: 290px;
  -webkit-filter: drop-shadow(60px 40px 60px rgba(110, 135, 159, .12));
  filter: drop-shadow(60px 40px 60px rgba(110, 135, 159, .12));
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden
}

.introduction .content-backdrop .container .content-back .introvideo-modal img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: 1
}

.introduction .content-backdrop .container .content-back .introvideo-modal .btn-play {
  z-index: 2;
  width: 60px;
  height: 60px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 50%;
  cursor: pointer
}

.introduction .content-backdrop .container .content-back .introvideo-modal .btn-play, .introduction .content-backdrop .container .content-back .introvideo-modal .btn-play img {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.introduction .content-backdrop .container .content-back .introvideo-modal .btn-play img {
  top: 450;
  width: 15px;
  -o-object-fit: contain;
  object-fit: contain;
  top: 50%
}

.introduction .content-backdrop .container .content-back .introvideo-modal .btn-play:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #fff;
  opacity: .4;
  -webkit-box-shadow: 0 4px 24px rgba(0, 0, 0, .25);
  -moz-box-shadow: 0 4px 24px rgba(0, 0, 0, .25);
  box-shadow: 0 4px 24px rgba(0, 0, 0, .25);
  -webkit-animation: pulse-ring 1.25s cubic-bezier(.215, .61, .355, 1) infinite;
  -moz-animation: pulse-ring 1.25s cubic-bezier(.215, .61, .355, 1) infinite;
  -o-animation: pulse-ring 1.25s cubic-bezier(.215, .61, .355, 1) infinite;
  animation: pulse-ring 1.25s cubic-bezier(.215, .61, .355, 1) infinite
}

.introduction .content-backdrop .container .content-back .introvideo-modal .btn-play:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .3);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, .3);
  box-shadow: 0 0 8px rgba(0, 0, 0, .3);
  -webkit-animation: pulse-dot 1.25s cubic-bezier(.455, .03, .515, .955) -.4s infinite;
  -moz-animation: pulse-dot 1.25s cubic-bezier(.455, .03, .515, .955) -.4s infinite;
  -o-animation: pulse-dot 1.25s cubic-bezier(.455, .03, .515, .955) -.4s infinite;
  animation: pulse-dot 1.25s cubic-bezier(.455, .03, .515, .955) -.4s infinite
}

@-webkit-keyframes pulse-ring {
  0% {
    -webkit-transform: scale(.43);
    transform: scale(.43)
  }
  80%, to {
    opacity: 0
  }
}

@-moz-keyframes pulse-ring {
  0% {
    -moz-transform: scale(.43);
    transform: scale(.43)
  }
  80%, to {
    opacity: 0
  }
}

@-o-keyframes pulse-ring {
  0% {
    -o-transform: scale(.43);
    transform: scale(.43)
  }
  80%, to {
    opacity: 0
  }
}

@keyframes pulse-ring {
  0% {
    -webkit-transform: scale(.43);
    -moz-transform: scale(.43);
    -o-transform: scale(.43);
    transform: scale(.43)
  }
  80%, to {
    opacity: 0
  }
}

@-webkit-keyframes pulse-dot {
  0% {
    -webkit-transform: scale(.8);
    transform: scale(.8)
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  to {
    -webkit-transform: scale(.8);
    transform: scale(.8)
  }
}

@-moz-keyframes pulse-dot {
  0% {
    -moz-transform: scale(.8);
    transform: scale(.8)
  }
  50% {
    -moz-transform: scale(1);
    transform: scale(1)
  }
  to {
    -moz-transform: scale(.8);
    transform: scale(.8)
  }
}

@-o-keyframes pulse-dot {
  0% {
    -o-transform: scale(.8);
    transform: scale(.8)
  }
  50% {
    -o-transform: scale(1);
    transform: scale(1)
  }
  to {
    -o-transform: scale(.8);
    transform: scale(.8)
  }
}

@keyframes pulse-dot {
  0% {
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8)
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
  }
  to {
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8)
  }
}

.introduction .content-backdrop .container .content-back .backdrop-img {
  width: 100%;
  padding-left: 40px;
  padding-top: 40px
}

.introduction .content-backdrop .container .content-back .backdrop-img img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.introduction .content-main {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}

.introduction .content-main .container {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  z-index: 2
}

.introduction .content-main .container, .introduction .content-main .container .inner-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 100%
}

.introduction .content-main .container .inner-content {
  padding-right: 100px;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 130px 0 100px 60px
}

.introduction .content-main .container .inner-content:before {
  position: absolute;
  width: 95px;
  height: 95px;
  background: url('./images/icons/bg-dots.svg') no-repeat 50%/contain;
  content: "";
  top: 100px;
  left: 30px
}

.introduction .content-main .container .inner-content .intro-makecall {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-bottom: auto
}

.introduction .content-main .container .inner-content .intro-makecall img {
  margin-right: 20px
}
.modal .content .text-content {
  height: 350px;
  overflow-y: scroll;
}
.modal .content .text-content::-webkit-scrollbar-track {
  background: transparent!important;
  border-radius: 10px;
}
.modal .content .text-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(31, 0, 67);
}
.modal .content .text-content::-webkit-scrollbar {
 width: 3px;
 background: transparent!important;
}
.introduction .content-main .container .inner-content .intro-makecall p {
  color: #1a279a;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 26px
}

.introduction .content-main .container .inner-content .intro-makecall p span {
  font-size: 22px
}

.introduction .content-main .container .inner-content .texting p {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 160%;
  letter-spacing: .004em;
  margin-bottom: 10px
}

.introduction .content-main .container .inner-content .texting p.framed {
  width: 100%;
  padding: 15px 30px;
  background: #efefef;
  font-size: 18px;
  margin-bottom: 60px
}

.introduction .content-main .container .inner-content .texting .title {
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 42px;
  letter-spacing: .004em;
  margin-bottom: 33px
}

.introduction .content-main .container .inner-content .btn-defaultsqr {
  margin-bottom: auto
}

.offers {
  padding: 100px 0
}

.offers .container .cards_row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-filter: drop-shadow(50px 60px 80px rgba(115, 137, 149, .12));
  filter: drop-shadow(50px 60px 80px rgba(115, 137, 149, .12));
  position: relative;
  z-index: 1
}

.offers .container .cards_row .card {
  width: 30%;
  height: 350px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.offers .container .cards_row .card .card-content {
  background: #f5f5f5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 50px;
  position: relative;
  overflow: hidden
}
.post_page h1 {
  color: #000!important;
}
.offers .container .cards_row .card .card-content:before {
  position: absolute;
  content: "";
  width: 100px;
  height: 100px;
  background: url('./images/icons/logo-white.svg') no-repeat 50%/contain;
  left: 20px;
  bottom: -20px
}

.offers .container .cards_row .card .card-content .icon {
  margin-bottom: 20px
}

.offers .container .cards_row .card .card-content .icon img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
  object-fit: contain
}

.offers .container .cards_row .card .card-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: auto
}

.offers .container .cards_row .card .card-content p.title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 132%;
  margin-bottom: 15px
}

.offers .container .cards_row .card .card-content .btn-more {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #fff;
  border: 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.offers .container .cards_row .card:nth-child(odd) .card-content {
  background: #fff
}

.offers .container .cards_row .card:nth-child(odd) .card-content .btn-more {
  background: #f6f9fb
}

.offers .container .cards_row .card:nth-child(odd) .card-content:before {
  background: url('./images/icons/logo-lightgrey.svg') no-repeat 50%/contain
}

.offers .container .cards_row .card:hover {
  z-index: 2;
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  -ms-transform: scale(1.01);
  -o-transform: scale(1.01);
  transform: scale(1.01);
  -webkit-box-shadow: 0 0 70px -15px rgba(34, 60, 80, .19);
  -moz-box-shadow: 0 0 70px -15px rgba(34, 60, 80, .19);
  box-shadow: 0 0 70px -15px rgba(34, 60, 80, .19)
}

.offers .container .cards_row .card:hover .card-content .btn-more {
  -webkit-transform: translateX(15px);
  -moz-transform: translateX(15px);
  -ms-transform: translateX(15px);
  -o-transform: translateX(15px);
  transform: translateX(15px)
}

.offers .container .cards_row .first {
  width: 40%;
  padding: 0 30px 30px 0
}

.offers .container .cards_row .first h2 {
  color: #000;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 124%;
  margin-bottom: 15px
}

.offers .container .cards_row .first p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: .004em;
  color: #37576f;
  margin-bottom: 40px
}

.offers .container .cards_row .first .btn-defaultsqr {
  font-size: 16px;
  line-height: 160%;
  padding: 15px 50px;
  letter-spacing: -.5px
}

.contactus {
  padding: 50px 0 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.contactus h2 {
  margin-left: 150px;
  margin-bottom: 10px
}

.contactus .contactus-line {
  background: #404040;
  width: 100vw;
  height: 100px;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.contactus .contactus-line .container {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.contactus .contactus-line .container .img {
  padding: 0 40px
}

.contactus .contactus-line .container .img .holder {
  width: 200px;
  height: 200px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #fff;
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#dfdfdf));
  background: -webkit-linear-gradient(top, #fff, #dfdfdf);
  background: -moz-linear-gradient(top, #fff 0, #dfdfdf 100%);
  background: -o-linear-gradient(top, #fff 0, #dfdfdf 100%);
  background: linear-gradient(180deg, #fff, #dfdfdf);
  -webkit-filter: drop-shadow(10px 14px 64px rgba(58, 65, 131, .29));
  filter: drop-shadow(10px 14px 64px rgba(58, 65, 131, .29))
}

.contactus .contactus-line .container .form, .contactus .contactus-line .container .img .holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.contactus .contactus-line .container .form {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.contactus .contactus-line .container .form .input {
  height: 55px;
  padding: 0 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background: #fff;
  -webkit-box-shadow: 0 20px 36px rgba(43, 33, 22, .03);
  -moz-box-shadow: 0 20px 36px rgba(43, 33, 22, .03);
  box-shadow: 0 20px 36px rgba(43, 33, 22, .03);
  -webkit-border-radius: 27.5px;
  -moz-border-radius: 27.5px;
  border-radius: 27.5px;
  margin-right: 15px
}

.contactus .contactus-line .container .form .input img {
  margin-right: 10px
}

.contactus .contactus-line .container .form .input input {
  font-weight: 700;
  font-size: 14px;
  color: #707070;
  border: 0;
  background: none
}

.contactus .contactus-line .container .form .form-submit {
  height: 55px;
  padding: 0 35px;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  background: #fff;
  border: 0;
  cursor: pointer;
  -webkit-border-radius: 27.5px;
  -moz-border-radius: 27.5px;
  border-radius: 27.5px;
  margin: 0 10px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.contactus .contactus-line .container .form .form-submit:hover {
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  -ms-transform: scale(1.01);
  -o-transform: scale(1.01);
  transform: scale(1.01)
}

.achievements {
  overflow: visible;
  position: relative
}

.achievements:before {
  z-index: -1;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90%;
  background: #f4f4f4
}

.achievements .container {
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.achievements .container, .achievements .container .img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center
}

.achievements .container .img {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  width: 40%;
  height: 100%;
  position: relative;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.achievements .container .img img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex
}

.achievements .container .img img.bg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%
}

.achievements .container .img img.person {
  position: relative;
  z-index: 2;
  width: 90%
}

.achievements .container .text {
  width: 60%;
  padding: 20px;
  padding-top: 10%
}

.achievements .container .text h2 {
  width: 500px;
  margin-bottom: 35px
}

.achievements .container .text ul li {
  min-height: 40px;
  margin-bottom: 10px;
  background: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 10px 15px;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px
}

.achievements .container .text ul li, .achievements .container .text ul li:before {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex
}

.achievements .container .text ul li:before {
  width: 12px;
  height: 12px;
  content: "";
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #939393;
  margin-right: 15px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.resulsteps {
  background-color: #87cefa;
  background: url('./images/steps-bg.png') no-repeat;
  background-position: top;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  padding: 50px 0;
  padding-bottom: 0
}

.resulsteps .container h2 {
  text-align: center;
  width: 600px;
  margin: 0 auto;
  margin-bottom: 50px
}

.resulsteps .container .items_row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px
}

.resulsteps .container .items_row .item {
  width: 33.333%;
  padding: 0 15px;
  margin-bottom: 25px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  cursor: pointer
}

.resulsteps .container .items_row .item .item-content {
  background: #fff;
  -webkit-box-shadow: 30px 9px 80px rgba(50, 19, 21, .05);
  -moz-box-shadow: 30px 9px 80px rgba(50, 19, 21, .05);
  box-shadow: 30px 9px 80px rgba(50, 19, 21, .05);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 25px 45px;
  position: relative;
  height: 100%
}

.resulsteps .container .items_row .item .item-content:hover {
  -webkit-box-shadow: 0 9px 80px rgba(50, 19, 21, .15);
  -moz-box-shadow: 0 9px 80px rgba(50, 19, 21, .15);
  box-shadow: 0 9px 80px rgba(50, 19, 21, .15)
}

.resulsteps .container .items_row .item .item-content:hover:before {
  opacity: .2
}

.resulsteps .container .items_row .item .item-content:before {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background: url('./images/icons/logo-gradientblack.svg') no-repeat 50%/contain;
  content: "";
  opacity: .05;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.resulsteps .container .items_row .item .item-content span {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  letter-spacing: .004em;
  margin-bottom: 15px;
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: hsla(0, 0%, 77%, .3);
  -webkit-box-shadow: 24px 30px 77px rgba(0, 0, 0, .2);
  -moz-box-shadow: 24px 30px 77px rgba(0, 0, 0, .2);
  box-shadow: 24px 30px 77px rgba(0, 0, 0, .2);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%
}

.resulsteps .container .items_row .item .item-content p {
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  -webkit-font-feature-settings: "ordn" on;
  -moz-font-feature-settings: "ordn" on;
  font-feature-settings: "ordn" on;
  color: #000
}

.resulsteps .container .leaveinfo {
  background: #404040;
  margin: 50px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px 40px
}

.resulsteps .container .leaveinfo p {
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  color: #fff;
  opacity: .8;
  width: 50%;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.resulsteps .container .leaveinfo p.title {
  display: none
}

.resulsteps .container .leaveinfo .form {
  max-width: 50%;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 65px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #b4b6c4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px
}

.resulsteps .container .leaveinfo .form input {
  padding: 0 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  line-height: 26px;
  color: #b4b6c4;
  border: 0;
  background: none
}

.resulsteps .container .leaveinfo .form button, .resulsteps .container .leaveinfo .form input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px
}

.resulsteps .container .leaveinfo .form button {
  height: 85%;
  padding: 0 35px;
  line-height: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 5px
}

.applications {
  overflow: visible;
  padding: 50px 0
}

.applications .container h2 {
  text-align: center;
  width: 650px;
  margin: 0 auto;
  margin-bottom: 50px
}

.applications .container .applicatoins-photo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.applications .container .applicatoins-photo .item {
  width: 25%;
  padding: 0 10px
}

.applications .container .applicatoins-photo .item h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 50px;
  text-align: center
}

.applications .container .applicatoins-photo .item .image {
  position: relative;
  width: 100%
}

.applications .container .applicatoins-photo .item .image .bg {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%)
}

.applications .container .applicatoins-photo .item .image .image-item {
  z-index: 2;
  position: relative;
  width: 55%;
  margin: 0 auto;
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .08);
  -moz-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .08);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .08)
}

.applications .container .applicatoins-photo .item .image .image-item img {
  width: 100%;
  z-index: 2;
  position: relative
}

.applications .container .applicatoins-photo .item:first-child .image .image-item:after {
  content: "";
  width: 100px;
  height: 200px;
  background: url('./images/icons/arrow-pointer.svg') no-repeat 50%;
  position: absolute;
  bottom: -100px;
  left: -50px;
  z-index: 1
}

.applications .container .applications-desc {
  padding: 100px 50px;
  padding-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.applications .container .applications-desc .text {
  width: 60%
}

.applications .container .applications-desc .text li, .applications .container .applications-desc .text p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  margin-bottom: 25px
}

.applications .container .applications-desc .text li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 10px
}

.applications .container .applications-desc .text li:before {
  content: "";
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  margin-right: 10px;
  height: 20px;
  background: url('./images/icons/arrow-checkpoint.svg') no-repeat 50%
}

.applications .container .applications-desc .img {
  width: 380px;
  height: 360px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 25px
}

.applications .container .applications-desc .img img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.applications .container .btn-defaultsqr {
  display: none
}

.readyprograms {
  padding: 50px 0;
  padding-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative
}

.readyprograms:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "";
  background: #f1f1f1;
  width: 55%
}

.readyprograms .container {
  position: relative;
  overflow: hidden;
  padding-bottom: 30px
}

.readyprograms .container h2 {
  width: 600px;
  margin-bottom: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex
}

.readyprograms .container .buttons {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -moz-box-orient: horizontal;
  -moz-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse
}

.readyprograms .container .buttons, .readyprograms .container .buttons .readyprograms-swiper-button-next, .readyprograms .container .buttons .readyprograms-swiper-button-prev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.readyprograms .container .buttons .readyprograms-swiper-button-next, .readyprograms .container .buttons .readyprograms-swiper-button-prev {
  width: 45px;
  height: 35px;
  background: url('./images/icons/arrow-slider.svg') no-repeat 50%;
  cursor: pointer;
  margin: 0 15px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.readyprograms .container .buttons .readyprograms-swiper-button-next.swiper-button-disabled, .readyprograms .container .buttons .readyprograms-swiper-button-next:hover, .readyprograms .container .buttons .readyprograms-swiper-button-prev.swiper-button-disabled, .readyprograms .container .buttons .readyprograms-swiper-button-prev:hover {
  opacity: .7
}

.readyprograms .container .buttons .readyprograms-swiper-button-next {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg)
}

.readyprograms .container .buttons span {
  height: 18px;
  width: 1px;
  background: #3a4183;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex
}

.readyprograms .container .swiper-wrapper {
  margin-bottom: 50px
}

.readyprograms .container .swiper-wrapper .application-item .item-content {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  overflow: hidden;
  position: relative;
  padding-bottom: 70%
}

.readyprograms .container .swiper-wrapper .application-item .item-content img {
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute
}

.readyprograms .container .swiper-wrapper .application-item .item-content .holder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#303030));
  background: -webkit-linear-gradient(top, transparent, #303030);
  background: -moz-linear-gradient(top, transparent 0, #303030 100%);
  background: -o-linear-gradient(top, transparent 0, #303030 100%);
  background: linear-gradient(180deg, transparent, #303030);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 30px
}

.readyprograms .container .swiper-wrapper .application-item .item-content .holder p {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 1.2;
  color: #fff
}

.readyprograms .container .swiper-wrapper .application-item .item-content:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1)
}

.readyprograms .container .installapp-btn {
  margin: 0 auto;
  display: inline-block;
  padding: 20px 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  margin: 0;
  margin-bottom: -20px
}

.feedbacks {
  height: 650px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: visible;
  padding: 50px 0
}

.feedbacks, .feedbacks .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
 
}

.feedbacks .container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative
}

.feedbacks .container h2 {
  width: 60%;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-bottom: 30px
}

.feedbacks .container .buttons {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.feedbacks .container .buttons .feedback-swiper-button-next, .feedbacks .container .buttons .feedback-swiper-button-prev {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin: 0 10px;
  background: url('./images/icons/arrow-slider-long-white.svg') no-repeat 50% #1a279a;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  cursor: pointer
}

.feedbacks .container .buttons .feedback-swiper-button-next.swiper-button-disabled, .feedbacks .container .buttons .feedback-swiper-button-next:hover, .feedbacks .container .buttons .feedback-swiper-button-prev.swiper-button-disabled, .feedbacks .container .buttons .feedback-swiper-button-prev:hover {
  background: url('./images/icons/arrow-slider-long.svg') no-repeat 50% #eaecff
}

.feedbacks .container .buttons .feedback-swiper-button-next {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg)
}

.feedbacks .container .feedback-swiper {
  position: relative
}

.feedbacks .container .feedback-swiper:before {
  position: absolute;
  bottom: -50px;
  left: 200px;
  width: 250px;
  height: 180px;
  background: url('./images/icons/bg-dots-triangl.svg') no-repeat 50%/cover;
  content: ""
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide.swiper-slide-active {
  opacity: 1
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .img {
  width: 30%;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translatey(25px);
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .img:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  right: -30px;
  top: 80px;
  background: #6b6b6b;
  z-index: 0
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .img:after {
  position: absolute;
  width: 90px;
  height: 90px;
  background: url('./images/quote.svg') no-repeat 50% #242424;
  content: "";
  top: 7px;
  right: -10px;
  z-index: 2
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .img img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  z-index: 1
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .text {
  background: #fff;
  border: 1px solid rgba(118, 129, 146, .1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 4px 20px 100px rgba(8, 21, 38, .06);
  -moz-box-shadow: 4px 20px 100px rgba(8, 21, 38, .06);
  box-shadow: 4px 20px 100px rgba(8, 21, 38, .06);
  width: 70%;
  z-index: 2;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  padding: 40px 30px 50px 50px;
  border-left: 12px solid #1e1e1e;
  -webkit-box-shadow: 4px 25px 60px rgba(30, 40, 52, .08);
  -moz-box-shadow: 4px 25px 60px rgba(30, 40, 52, .08);
  box-shadow: 4px 25px 60px rgba(30, 40, 52, .08)
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 127%;
  margin-bottom: 5px
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .position {
  font-weight: 700;
  font-size: 22px;
  line-height: 132%;
  color: #7a7a98
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .position, .feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .rating {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 20px
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .rating span {
  width: 20px;
  height: 20px;
  margin: 0 4px;
  background: url('./images/icons/star.svg') no-repeat 50%
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .rating span.disabled {
  -webkit-filter: grayscale(1);
  filter: grayscale(1)
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .factory {
  font-weight: 700;
  font-size: 15px;
  line-height: 132%;
  text-align: right;
  color: #6159a2
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .text > p {
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
  margin: 0
}

.news {
  padding: 50px 0
}

.news .container {
  overflow: hidden
}

.news .container .titling {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px
}

.news .container .titling .text p {
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #202020;
  mix-blend-mode: normal;
  opacity: .7
}

.news .container .titling .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.news .container .titling .buttons .news-swiper-button-next, .news .container .titling .buttons .news-swiper-button-prev {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin: 0 10px;
  background: url('./images/icons/arrow-slider-long-white.svg') no-repeat 50% #1a279a;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  cursor: pointer
}

.news .container .titling .buttons .news-swiper-button-next.swiper-button-disabled, .news .container .titling .buttons .news-swiper-button-next:hover, .news .container .titling .buttons .news-swiper-button-prev.swiper-button-disabled, .news .container .titling .buttons .news-swiper-button-prev:hover {
  background: url('./images/icons/arrow-slider-long.svg') no-repeat 50% #eaecff
}

.news .container .titling .buttons .news-swiper-button-next {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg)
}

.news .container .news-swiper-slide .item-content .img {
  width: 100%;
  position: relative;
  padding-bottom: 65%;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px
}

.news .container .news-swiper-slide .item-content .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  position: absolute
}

.news .container .news-swiper-slide .item-content .category {
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3a4183;
  margin-bottom: 10px
}

.news .container .news-swiper-slide .item-content .title {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px
}

.news .container .news-swiper-slide .item-content:hover .img img {
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
  -ms-transform: scale(1.07);
  -o-transform: scale(1.07);
  transform: scale(1.07)
}

footer {
  background: #14151c;
  padding-top: 60px;
  position: relative
}

footer:after {
  top: 0;
  right: 10%;
  width: 500px;
  height: 300px;
  background: url('./images/footer-bg.svg') no-repeat 50%/contain
}

footer:after, footer:before {
  content: "";
  position: absolute;
  z-index: 0
}

footer:before {
  bottom: 0;
  left: 0;
  width: 140px;
  height: 200px;
  background: url('./images/footer-bg-1.svg') no-repeat 50%/contain
}

footer .container {
  z-index: 1;
  position: relative
}

footer .container h2 {
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
  color: #fff;
  margin-bottom: 20px
}

footer .container > p {
  width: 480px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #b4b6c4;
  margin-bottom: 32px
}

footer .container .form {
  max-width: 50%;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 65px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #b4b6c4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-bottom: 60px
}

footer .container .form input {
  padding: 0 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  line-height: 26px;
  color: #b4b6c4;
  border: 0;
  background: none
}

footer .container .form button, footer .container .form input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px
}

footer .container .form button {
  height: 85%;
  padding: 0 35px;
  line-height: 20px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 5px
}

footer .container .line {
  width: 100%;
  height: 1px;
  background: #fff;
  margin-bottom: 30px;
  opacity: .2
}

footer .container .line, footer .container .under-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex
}

footer .container .under-content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  margin-bottom: 100px
}

footer .container .under-content .footer-logo {
  margin-bottom: 20px
}

footer .container .under-content .content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%
}

footer .container .under-content .content .desc {
  width: 35%
}

footer .container .under-content .content .desc p {
  width: 360px;
  font-size: 16px;
  line-height: 26px;
  color: #b4b6c4
}

footer .container .under-content .content .desc .footer-logo-m {
  display: none
}

footer .container .under-content .content .contacts {
  width: 25%
}

footer .container .under-content .content .contacts .contact-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 15px
}

footer .container .under-content .content .contacts .contact-link img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px
}

footer .container .under-content .content .contacts .contact-link p {
  font-size: 16px;
  line-height: 26px;
  color: #b4b6c4
}

footer .container .under-content .content .phone {
  width: 35%
}

footer .container .under-content .content .phone .makecall {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-bottom: auto
}

footer .container .under-content .content .phone .makecall img {
  margin-right: 20px
}

footer .container .under-content .content .phone .makecall p {
  color: #b4b6c4;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 26px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

footer .container .under-content .content .phone .makecall p:hover {
  opacity: .7
}

footer .container .under-content .content .phone .makecall p span {
  font-size: 22px
}

footer .outro-content {
  background: #272727;
  height: 70px;
  z-index: 2;
  position: relative
}

footer .outro-content .container {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

footer .outro-content .container p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  color: #b4b6c4;
  opacity: .8
}

footer .outro-content .container .socials {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

footer .outro-content .container .socials, footer .outro-content .container .socials a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

footer .outro-content .container .socials a {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 15px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

footer .outro-content .container .socials a:hover {
  opacity: .7
}

.newspage {
  padding: 150px 0;
  padding-bottom: 70px;
  background: url('./images/steps-bg.png') no-repeat;
  background-position: top;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto
}

.newspage .titling {
  width: 100%;
  margin-bottom: 50px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex
}

.newspage .titling .img {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 25%;
  width: 450px;
  margin-right: 30px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.newspage .titling .img img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute
}

.newspage .titling .title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.newspage .titling .title .category {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3a4183;
  margin-bottom: 10px;
  cursor: pointer;
}

.newspage .titling .title h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 38px
}

.newspage .titling:after {
  content: "";
  position: absolute;
  top: 0;
  right: -100px;
  width: 95px;
  height: 85px;
  background: url('./images/icons/bg-dots.svg') no-repeat 50%/contain
}

.newspage .content a, .newspage .content blockquote, .newspage .content p {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  margin-bottom: 30px;
  padding: 0 120px
}

.newspage .content ul {
  padding: 0 120px;
  margin-bottom: 30px
}

.newspage .content ul li {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px
}

.newspage .content table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px
}

.newspage .content table td, .newspage .content table th {
  text-align: left;
  padding: 7px;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: .02em;
  color: #171849
}

.newspage .content table tr:nth-child(2n) {
  background-color: #ededed
}

.newspage .content h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 30px;
  padding: 0 120px
}

.newspage .content .img {
  margin-bottom: 30px;
  max-height: 1000px
}

.newspage .content .img img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.modal {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}

.modal .holder {
  background: rgba(0, 0, 0, .52);
  width: 100%;
  height: 100%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 100px;
  padding-top: 70px;
  overflow-y: scroll
}

.modal .holder, .modal .holder .content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.modal .holder .content {
  background: #fff;
  width: 1000px;
  max-height: 535px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
  border-radius: 10px;
}

.modal .holder .content .success-content {
  padding: 50px 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.modal .holder .content .success-content h3 {
  font-weight: 800;
  font-size: 34px;
  line-height: 110%;
  text-align: center;
  margin-bottom: 60px
}

.modal .holder .content .success-content img {
  margin-bottom: 60px
}

.modal .holder .content .success-content p {
  font-weight: 600;
  font-size: 17px;
  line-height: 160%;
  text-align: center;
  margin-bottom: 50px
}

.modal .holder .content .success-content .btn-defaultsqr {
  width: 250px
}

.modal .holder .content .desc-content {
  padding: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.modal .holder .content .desc-content h3 {
  margin-bottom: 25px
}

.modal .holder .content .desc-content p {
  font-weight: 400;
  font-size: 18px;
  line-height: 31px
}

.modal .holder .content .form {
  width: 60%;
  padding: 20px 50px;
}

.modal .holder .content .form h3 {
  font-weight: 800;
  font-size: 34px;
  line-height: 110%;
  margin-bottom: 20px
}

.modal .holder .content .form p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 25px
}

.modal .holder .content .form p.contact {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 15px
}

.modal .holder .content .form a {
  color: #37576f;
  font-size: 26px
}

.modal .holder .content .form input {
  width: 100%;
  margin-bottom: 20px;
  background: #fff;
  height: 50px;
  border: 1px solid #b4b6c4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 25px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px
}

.modal .holder .content .form .btn-defaultsqr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.modal .holder .content .img {
  width: 40%;
  position: relative;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius:0  10px 10px 0;
  overflow: hidden;
}

.modal .holder .content .img > img {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.modal .holder .content .img .logo {
  position: absolute;
  top: 40px;
  left: 30px
}

.modal .holder .content .modal-close {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -10px;
  right: -10px;
  -webkit-transform: translate(100%, -100%);
  -moz-transform: translate(100%, -100%);
  -ms-transform: translate(100%, -100%);
  -o-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
  background: url('./images/icons/close.svg') no-repeat 50%/contain;
  cursor: pointer;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.modal .holder .content .modal-close:hover {
  opacity: .7
}

.modal.show {
  opacity: 1;
  visibility: visible
}

@media (max-width: 992px) {
  .modal .holder .content .desc-content, .modal .holder .content .form, .modal .holder .content .success-content {
    width: 100%
  }

  .modal .holder .content .img {
    display: none
  }
}

@media (max-width: 768px) {
  .modal .holder {
    padding: 100px 70px
  }

  .modal .holder .content {
    width: 100%
  }

  .modal .holder .content .desc-content, .modal .holder .content .form, .modal .holder .content .success-content {
    padding: 30px
  }

  .modal .holder .content .desc-content h3, .modal .holder .content .form h3, .modal .holder .content .success-content h3 {
    font-size: 28px;
    margin-bottom: 20px
  }

  .modal .holder .content .desc-content p, .modal .holder .content .form p, .modal .holder .content .success-content p {
    font-size: 16px
  }

  .modal .holder .content .desc-content input, .modal .holder .content .form input, .modal .holder .content .success-content input {
    height: 55px
  }

  .modal .holder .content .desc-content .btn-defaultsqr, .modal .holder .content .form .btn-defaultsqr, .modal .holder .content .success-content .btn-defaultsqr {
    height: 55px;
    padding: 0 50px;
    width: 100%;
    margin-bottom: 20px
  }
}

@media (max-width: 576px) {
  .modal .holder {
    padding: 100px 40px
  }

  .modal .holder .content {
    width: 100%
  }

  .modal .holder .content .desc-content, .modal .holder .content .form, .modal .holder .content .success-content {
    padding: 20px
  }

  .modal .holder .content .desc-content h3, .modal .holder .content .form h3, .modal .holder .content .success-content h3 {
    font-size: 24px;
    margin-bottom: 20px
  }

  .modal .holder .content .desc-content p, .modal .holder .content .form p, .modal .holder .content .success-content p {
    font-size: 16px
  }

  .modal .holder .content .desc-content input, .modal .holder .content .form input, .modal .holder .content .success-content input {
    height: 55px
  }

  .modal .holder .content .desc-content .btn-defaultsqr, .modal .holder .content .form .btn-defaultsqr, .modal .holder .content .success-content .btn-defaultsqr {
    height: 55px;
    padding: 0 50px;
    width: 100%;
    margin-bottom: 20px
  }

  .modal .holder .content .modal-close {
    right: 0;
    top: 0
  }
}

@media (max-width: 1160px) {
  header .navigation .additionals {
    width: 40%
  }

  header .navigation .links {
    width: 30%
  }

  header .navigation .links ul li {
    margin: 0 15px
  }

  header .navigation .links ul li a {
    font-size: 14px;
    white-space: nowrap
  }

  header.not-index:after {
    width: 41%
  }

  .container {
    max-width: 992px
  }

  .introduction .content-main .container .inner-content, .introduction:after {
    width: 60%
  }

  .introduction .content-main .container .inner-content .texting p {
    font-size: 18px;
    margin-bottom: 15px
  }

  .introduction .content-main .container .inner-content .texting p.framed {
    font-size: 16px;
    margin-bottom: 20px
  }

  .introduction .content-main .container .inner-content .texting .title {
    font-size: 34px;
    margin-bottom: 15px
  }

  .contactus {
    padding: 20px 0
  }

  .contactus h2 {
    padding: 0 15px;
    margin: 0;
    text-align: center;
    margin-bottom: 15px
  }

  .contactus .contactus-line .container .img {
    display: none
  }

  .contactus .contactus-line .container .form, .contactus .contactus-line .container .form .input {
    width: 100%
  }

  .achievements .container .text {
    padding: 20px 0
  }

  .achievements:before {
    height: 100%
  }

  .applications .container .applicatoins-photo .item h3 {
    font-size: 26px;
    margin-bottom: 20px
  }

  footer .container .under-content .content {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  footer .container .under-content .content .phone {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 20px;
    margin-bottom: 0
  }

  footer .container .under-content .content .contacts, footer .container .under-content .content .desc {
    width: 40%
  }
}

@media (max-width: 992px) {
  .text-title-st {
    font-size: 28px;
    text-align: center
  }

  header.not-index .navigation .logo img, header.not-index:after {
    display: none
  }

  header.not-index .navigation .logo .mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
  }

  .container {
    max-width: 100%
  }

  .introduction {
    padding-top: 80px;
    padding-bottom: 40px
  }

  .introduction .content-backdrop .container .content-back {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .introduction .content-backdrop .container .content-back .introvideo-modal {
    left: 20%
  }

  .introduction .content-backdrop .container .content-back .backdrop-img {
    width: 80%
  }

  .introduction .content-main {
    position: unset
  }

  .introduction .content-main .container .inner-content {
    width: unset;
    padding: 15px 0
  }

  .introduction .content-main .container .inner-content .intro-makecall, .introduction .content-main .container .inner-content:before {
    display: none
  }

  .introduction .content-main .container .inner-content .texting p {
    color: #fff;
    margin-bottom: 15px
  }

  .introduction .content-main .container .inner-content .texting p.framed {
    background: hsla(0, 0%, 100%, .1);
    padding: 20px
  }

  .introduction .content-main .container .inner-content .texting .title {
    color: #fff
  }

  .introduction:before {
    bottom: 0;
    height: unset
  }

  .introduction:after {
    display: none
  }

  .offers {
    padding: 50px 0
  }

  .offers .container .cards_row {
    -webkit-filter: none;
    filter: none
  }

  .offers .container .cards_row .first {
    width: 100%;
    text-align: center;
    margin-bottom: 35px;
    padding: 0
  }

  .offers .container .cards_row .card {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    height: auto
  }

  .offers .container .cards_row .card .card-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background: #f0f0f0 !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 25px 30px
  }

  .offers .container .cards_row .card .card-content .icon {
    margin-bottom: 20px;
    margin-right: 20px
  }

  .offers .container .cards_row .card .card-content p {
    width: 100%;
    z-index: 1
  }

  .offers .container .cards_row .card .card-content p.title {
    width: unset
  }

  .offers .container .cards_row .card .card-content .btn-more {
    position: absolute;
    right: 20px;
    top: 20px;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .09);
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, .09);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .09)
  }

  .offers .container .cards_row .card .card-content:before {
    left: unset;
    width: 110px;
    height: 110px;
    right: 5%;
    z-index: 0
  }

  .achievements .container .img {
    display: none
  }

  .achievements .container .text {
    width: 100%
  }

  .achievements .container .text h2 {
    width: unset;
    margin-bottom: 25px
  }

  .resulsteps .container .items_row .item {
    width: 50%;
    padding: 0 10px;
    margin-bottom: 20px
  }

  .resulsteps .container .leaveinfo {
    width: 100vw;
    margin: 0 -15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .resulsteps .container .leaveinfo p {
    width: 100%;
    text-align: center;
    margin-bottom: 20px
  }

  .resulsteps .container .leaveinfo .form {
    max-width: unset;
    width: 100%
  }

  .applications .container {
    overflow: hidden;
    position: relative
  }

  .applications .container .applications-swiper-container {
    width: 100%;
    position: relative
  }

  .applications .container .applications-swiper-container .applications-swiper-pagination {
    padding: 15px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .applications .container .applications-swiper-container .applications-swiper-pagination .swiper-pagination-bullet {
    background: #5b5b5b;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 6px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
    height: 6px;
    margin: 0 3px
  }

  .applications .container .applications-swiper-container .applications-swiper-pagination .swiper-pagination-bullet-active {
    width: 30px;
    background: #1a279a
  }

  .applications .container .applications-swiper-container .applications-swiper-navigation {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 5;
    right: 0;
    left: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
  }

  .applications .container .applications-swiper-container .applications-swiper-navigation .applications-swiper-button-next, .applications .container .applications-swiper-container .applications-swiper-navigation .applications-swiper-button-prev {
    width: 50px;
    height: 50px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background: url('./images/icons/arrow-slider-long-white.svg') no-repeat 50% #1a279a;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
    cursor: pointer
  }

  .applications .container .applications-swiper-container .applications-swiper-navigation .applications-swiper-button-next.swiper-button-disabled, .applications .container .applications-swiper-container .applications-swiper-navigation .applications-swiper-button-prev.swiper-button-disabled {
    background: url('./images/icons/arrow-slider-long.svg') no-repeat 50% #eaecff
  }

  .applications .container .applications-swiper-container .applications-swiper-navigation .applications-swiper-button-next {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg)
  }

  .applications .container .applicatoins-photo {
    width: 100%;
    -webkit-box-pack: unset;
    -webkit-justify-content: unset;
    -moz-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset
  }

  .applications .container .applicatoins-photo .item:first-child .image .image-item:after {
    display: none
  }

  .applications .container .applications-desc {
    padding: 20px 0
  }

  .feedbacks {
    height: unset
  }

  .feedbacks .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .feedbacks .container h2 {
    text-align: center
  }

  .feedbacks .container .buttons {
    position: unset;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -moz-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .img:after {
    display: none
  }

  footer .container .form {
    max-width: 80%
  }

  .newspage {
    padding-bottom: 50px
  }

  .newspage .titling {
    margin-bottom: 30px
  }

  .newspage .titling .img {
    width: 300px;
    margin-right: 20px
  }

  .newspage .titling .title h1 {
    font-size: 24px;
    line-height: 1.2
  }

  .newspage .titling:after {
    right: 0;
    opacity: .5
  }

  .newspage .content a, .newspage .content blockquote, .newspage .content p {
    padding: 0;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 15px
  }

  .newspage .content h2 {
    padding: 0;
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 1.5
  }

  .newspage .content .img {
    margin-bottom: 15px
  }

  .newspage .content ul {
    padding: 0;
    margin-bottom: 15px
  }

  .newspage .content ul li {
    font-size: 18px;
    line-height: 1.5
  }
}

@media (max-width: 768px) {
  header {
    height: 80px
  }

  header .navigation {
    padding: 0 30px
  }

  header .navigation .logo img {
    display: none
  }

  header .navigation .logo .mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
  }

  

  header .navigation .mobile-buttons, header .navigation .mobile-buttons a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
  }

  header .navigation .mobile-buttons a {
    position: relative;
    z-index: 2;
    width: 50px;
    height: 50px;
    cursor: pointer;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  header .navigation .mobile-buttons a, header .navigation .mobile-buttons a:before {
    background: #1a279a;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
  }

  header .navigation .mobile-buttons a:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    -webkit-animation: pulsation 2s infinite;
    -moz-animation: pulsation 2s infinite;
    -o-animation: pulsation 2s infinite;
    animation: pulsation 2s infinite
  }

  header .navigation .mobile-buttons a img {
    -webkit-filter: brightness(10);
    filter: brightness(10);
    width: 40%;
    height: 40%;
    -o-object-fit: contain;
    object-fit: contain;
    z-index: 3
  }

  @-webkit-keyframes pulsation {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: .9;
      z-index: 1
    }
    to {
      -webkit-transform: scale(1.4);
      transform: scale(1.4);
      opacity: 0;
      z-index: 1
    }
  }@-moz-keyframes pulsation {
     0% {
       -moz-transform: scale(1);
       transform: scale(1);
       opacity: .9;
       z-index: 1
     }
     to {
       -moz-transform: scale(1.4);
       transform: scale(1.4);
       opacity: 0;
       z-index: 1
     }
   }@-o-keyframes pulsation {
      0% {
        -o-transform: scale(1);
        transform: scale(1);
        opacity: .9;
        z-index: 1
      }
      to {
        -o-transform: scale(1.4);
        transform: scale(1.4);
        opacity: 0;
        z-index: 1
      }
    }@keyframes pulsation {
       0% {
         -webkit-transform: scale(1);
         -moz-transform: scale(1);
         -o-transform: scale(1);
         transform: scale(1);
         opacity: .9;
         z-index: 1
       }
       to {
         -webkit-transform: scale(1.4);
         -moz-transform: scale(1.4);
         -o-transform: scale(1.4);
         transform: scale(1.4);
         opacity: 0;
         z-index: 1
       }
     }.contactus {
        padding: 0
      }

  .contactus h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 110%;
    text-align: left
  }

  .contactus .contactus-line {
    height: unset;
    background: -webkit-gradient(linear, left top, left bottom, from(#5b5b5b), to(#121429));
    background: -webkit-linear-gradient(top, #5b5b5b, #121429);
    background: -moz-linear-gradient(top, #5b5b5b 0, #121429 100%);
    background: -o-linear-gradient(top, #5b5b5b 0, #121429 100%);
    background: linear-gradient(180deg, #5b5b5b, #121429)
  }

  .contactus .contactus-line .container .form {
    padding: 30px 20px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .contactus .contactus-line .container .form .input {
    background: hsla(0, 0%, 100%, .16);
    margin: 0;
    margin-bottom: 15px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px
  }

  .contactus .contactus-line .container .form .input img {
    -webkit-filter: brightness(10);
    filter: brightness(10);
    left: 20px
  }

  .contactus .contactus-line .container .form .input input {
    color: #fff
  }

  .contactus .contactus-line .container .form .input input::-webkit-input-placeholder {
    color: #fff
  }

  .contactus .contactus-line .container .form .input input:-moz-placeholder, .contactus .contactus-line .container .form .input input::-moz-placeholder {
    color: #fff
  }

  .contactus .contactus-line .container .form .input input:-ms-input-placeholder, .contactus .contactus-line .container .form .input input::-ms-input-placeholder {
    color: #fff
  }

  .contactus .contactus-line .container .form .input input::placeholder {
    color: #fff
  }

  .contactus .contactus-line .container .form .form-submit {
    width: 100%;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;
    margin: 0;
    margin-top: 10px
  }

  .resulsteps .container .leaveinfo {
    background: -webkit-gradient(linear, left top, left bottom, from(#5b5b5b), to(#121429));
    background: -webkit-linear-gradient(top, #5b5b5b, #121429);
    background: -moz-linear-gradient(top, #5b5b5b 0, #121429 100%);
    background: -o-linear-gradient(top, #5b5b5b 0, #121429 100%);
    background: linear-gradient(180deg, #5b5b5b, #121429);
    padding: 30px 20px
  }

  .resulsteps .container .leaveinfo p {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #fff;
    opacity: .8;
    margin-bottom: 25px;
    text-align: left
  }

  .resulsteps .container .leaveinfo p.title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 800;
    text-align: left;
    font-size: 24px;
    line-height: 120%;
    color: #fff;
    opacity: 1;
    margin-bottom: 10px
  }

  .resulsteps .container .leaveinfo .form {
    border: 0;
    height: unset;
    background: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .resulsteps .container .leaveinfo .form input {
    background: hsla(0, 0%, 100%, .16);
    margin: 0;
    margin-bottom: 15px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;
    color: #fff;
    height: 60px;
    width: 100%;
    position: relative
  }

  .resulsteps .container .leaveinfo .form input::-webkit-input-placeholder {
    color: #fff
  }

  .resulsteps .container .leaveinfo .form input:-moz-placeholder, .resulsteps .container .leaveinfo .form input::-moz-placeholder {
    color: #fff
  }

  .resulsteps .container .leaveinfo .form input:-ms-input-placeholder, .resulsteps .container .leaveinfo .form input::-ms-input-placeholder {
    color: #fff
  }

  .resulsteps .container .leaveinfo .form input::placeholder {
    color: #fff
  }

  .resulsteps .container .leaveinfo .form button {
    height: 60px;
    width: 100%;
    background: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #000;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px
  }

  .applications .container .applications-desc .img {
    display: none
  }

  .applications .container .applications-desc .text {
    width: 100%
  }

  .readyprograms {
    padding: 30px 0;
    padding-bottom: 0
  }

  .readyprograms:before {
    width: 100%
  }

  .readyprograms .container {
    padding-bottom: 80px
  }

  .readyprograms .container h2 {
    text-align: center;
    margin: 0;
    margin-bottom: 30px;
    width: 100%
  }

  .readyprograms .container .buttons {
    top: unset;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%)
  }

  .readyprograms .container .swiper-wrapper {
    margin-bottom: 30px
  }

  .readyprograms .container .installapp-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto
  }

  .feedbacks {
    padding: 30px 0
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .text, .feedbacks .container h2 {
    width: 100%;
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .rating {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-top: 10px
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .position {
    display: none
  }

 

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .img img {
    width: 80%
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .img:before {
    width: 130px;
    height: 130px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    bottom: 0;
    left: 0;
    top: unset;
    right: unset;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none
  }

  .feedbacks .container .buttons {
    margin-top: 15px
  }

  footer {
    padding: 30px 0;
    padding-bottom: 0
  }

  footer .container h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 15px
  }

  footer .container > p {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    width: unset;
    color: #fff;
    opacity: .8
  }

  footer .container .form {
    width: 100%;
    max-width: unset;
    border: 0;
    height: unset;
    background: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 30px
  }

  footer .container .form input {
    background: #3a3a40;
    margin: 0;
    margin-bottom: 15px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;
    color: #fff;
    height: 60px;
    width: 100%;
    position: relative
  }

  footer .container .form input::-webkit-input-placeholder {
    color: #fff
  }

  footer .container .form input:-moz-placeholder, footer .container .form input::-moz-placeholder {
    color: #fff
  }

  footer .container .form input:-ms-input-placeholder, footer .container .form input::-ms-input-placeholder {
    color: #fff
  }

  footer .container .form input::placeholder {
    color: #fff
  }

  footer .container .form button {
    height: 60px;
    width: 100%;
    background: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #000;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px
  }

  footer .container .under-content {
    margin-bottom: 30px
  }

  footer .container .under-content .footer-logo {
    display: none
  }

  footer .container .under-content .content {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  footer .container .under-content .content, footer .container .under-content .content .desc {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  footer .container .under-content .content .desc {
    width: 100%;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -moz-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -moz-box-pack: start
  }

  footer .container .under-content .content .desc .footer-logo-m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0;
    margin-right: 15px;
    padding: 0;
    width: 40px;
    height: 40px
  }

  footer .container .under-content .content .desc p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 120%
  }

  footer .container .under-content .content .contacts {
    width: 100%;
    margin-bottom: 10px
  }

  footer .container .under-content .content .phone {
    width: 100%;
    margin: 0;
    margin-bottom: 25px
  }

  footer .container .under-content .content .phone, footer .container .under-content .content .phone .makecall {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  footer .container .under-content .content .phone .makecall p {
    font-size: 22px;
    line-height: 26px;
    color: #b4b6c4
  }

  footer .container .under-content .content .phone .makecall p span {
    font-size: 16px
  }

  footer .outro-content .container {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  footer .outro-content .container .socials {
    display: none
  }
}


@media (max-width: 576px) {
  .container {
    padding: 0 20px
  }

  .text-title-st {
    font-size: 24px;
    text-align: left
  }

  .btn-defaultsqr {
    font-weight: 600;
    font-size: 15px;
    padding: 20px 45px;
    line-height: 140%
  }

  .introduction .content-backdrop .container .content-back {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    min-height: 210px
  }

  .introduction .content-backdrop .container .content-back .introvideo-modal {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 180px;
    height: 210px
  }

  .introduction .content-backdrop .container .content-back .backdrop-img {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0
  }

  .introduction .content-backdrop .container .content-back .backdrop-img img {
    width: 80%
  }

  .introduction .content-main .container .inner-content .texting p {
    display: none
  }

  .introduction .content-main .container .inner-content .texting p.framed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 132%
  }

  .introduction .content-main .container .inner-content .texting .title {
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px
  }

  .introduction .content-main .container .inner-content .btn-defaultsqr {
    display: block;
    width: 100%
  }

  .offers .container .cards_row .first {
    margin-bottom: 25px
  }

  .offers .container .cards_row .first h2 {
    font-weight: 800;
    font-size: 24px;
    text-align: left;
    margin: 0
  }

  .offers .container .cards_row .first h2 span {
    color: #1a279a
  }

  .offers .container .cards_row .first .btn-defaultsqr, .offers .container .cards_row .first p {
    display: none
  }

  .offers .container .cards_row .card {
    margin-bottom: 15px
  }

  .offers .container .cards_row .card .card-content {
    padding: 20px;
    -webkit-box-shadow: 0;
    -moz-box-shadow: 0;
    box-shadow: 0
  }

  .offers .container .cards_row .card .card-content .icon {
    margin-bottom: 15px;
    width: 40px;
    height: 40px;
    background: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .offers .container .cards_row .card .card-content .icon img {
    width: 60%;
    height: 60%;
    -o-object-fit: contain;
    object-fit: contain
  }

  .offers .container .cards_row .card .card-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px
  }

  .offers .container .cards_row .card .card-content p.title {
    font-size: 18px;
    line-height: 120%
  }

  .achievements {
    padding: 30px 0;
    background: #f0f0f0
  }

  .achievements .container .text {
    padding: 0
  }

  .achievements .container .text h2 {
    text-align: left;
    padding: 0;
    font-weight: 800;
    font-size: 24px;
    line-height: 110%;
    margin-bottom: 20px
  }

  .achievements .container .text ul li {
    min-height: 85px;
    background: #1b1d2f;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 15px 25px;
    padding-right: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: hsla(0, 0%, 94%, .88);
    font-weight: 600;
    font-size: 15px;
    line-height: 130%
  }

  .achievements .container .text ul li:before {
    position: absolute;
    right: 20px;
    width: 25px;
    height: 25px;
    background: url('./images/icons/arrow-checkpoint-blue.svg') no-repeat 50% #f7f7f7
  }

  .resulsteps {
    padding: 0;
    background: #f0f0f0
  }

  .resulsteps .container h2 {
    width: unset;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    text-align: left
  }

  .resulsteps .container .items_row .item {
    width: 100%;
    margin-bottom: 15px
  }

  .resulsteps .container .items_row .item .item-content {
    -webkit-border-radius: 11px;
    -moz-border-radius: 11px;
    border-radius: 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 20px 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .resulsteps .container .items_row .item .item-content span {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    margin-right: 20px;
    width: 30px;
    height: 30px;
    font-size: 14px
  }

  .resulsteps .container .items_row .item .item-content p {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 128%;
    color: rgba(0, 0, 0, .6)
  }

  .resulsteps .container .items_row .item .item-content:before {
    display: none
  }

  .resulsteps .container .leaveinfo {
    margin: 0 -20px
  }

  .applications {
    padding: 30px 0
  }

  .applications .container h2 {
    width: unset;
    margin-bottom: 25px;
    text-align: left
  }

  .applications .container .applications-swiper-container {
    width: 80%
  }

  .applications .container .applications-swiper-container .applications-swiper-navigation {
    width: 100vw;
    left: -20px;
    padding: 0 20px
  }

  .applications .container .applicatoins-photo .item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -moz-box-orient: vertical;
    -moz-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    opacity: .5;
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8);
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
  }

  .applications .container .applicatoins-photo .item.swiper-slide-active {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  .applications .container .applicatoins-photo .item h3 {
    margin: 0;
    margin-top: 15px;
    font-size: 24px
  }

  .applications .container .applications-desc {
    padding-bottom: 0
  }

  .applications .container .applications-desc .text p {
    font-size: 16px;
    line-height: 130%;
    color: rgba(0, 0, 0, .7);
    margin-bottom: 16px
  }

  .applications .container .btn-defaultsqr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .readyprograms:before {
    background: #fafafa
  }

  .readyprograms .container {
    padding-bottom: 60px
  }

  .readyprograms .container h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 25px;
    text-align: left
  }

  .readyprograms .container .readyprograms-swiper {
    width: 80%
  }

  .readyprograms .container .readyprograms-swiper .swiper-wrapper .application-item {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    overflow: hidden
  }

  .readyprograms .container .readyprograms-swiper .swiper-wrapper .application-item .item-content .holder {
    padding: 20px 15px
  }

  .readyprograms .container .readyprograms-swiper .swiper-wrapper .application-item .item-content .holder p {
    font-size: 16px
  }

  .feedbacks .container h2 {
    text-align: left;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 20px;
    padding-right: 0!important;
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .img{
    display: none;
  }

  .feedbacks .swiper-container{
    overflow: hidden;
    width: 100%;
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .factory{
    text-align: left;
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .text {
    border: 0;
    -webkit-box-shadow: 4px 20px 100px rgba(8, 21, 38, .06);
    -moz-box-shadow: 4px 20px 100px rgba(8, 21, 38, .06);
    box-shadow: 4px 20px 100px rgba(8, 21, 38, .06);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    padding: 20px;
    min-height: auto!important;
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #2e2e46;
    margin-bottom: 10px
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .rating {
    margin: 0
  }

  .feedbacks .container .feedback-swiper .feedback-swiper-slide .text .titling .rating span {
    width: 15px;
    height: 15px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    margin: 0 2px;
    margin-bottom: 10px
  }

  .feedbacks .container .feedback-swiper:before {
    display: none
  }

  .feedbacks .container .buttons .feedback-swiper-button-next, .feedbacks .container .buttons .feedback-swiper-button-prev {
    width: 45px;
    height: 35px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: url('./images/icons/arrow-slider.svg') no-repeat 50%;
    cursor: pointer;
    margin: 0 15px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
    background-color: none
  }

  .feedbacks .container .buttons .feedback-swiper-button-next.swiper-button-disabled, .feedbacks .container .buttons .feedback-swiper-button-next:hover, .feedbacks .container .buttons .feedback-swiper-button-prev.swiper-button-disabled, .feedbacks .container .buttons .feedback-swiper-button-prev:hover {
    background: url('./images/icons/arrow-slider.svg') no-repeat 50%;
    opacity: .7
  }

  .feedbacks .container .buttons .feedback-swiper-button-next {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg)
  }

  .feedbacks .container .buttons span {
    height: 18px;
    width: 1px;
    background: #3a4183;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex
  }

  .news {
    padding: 0;
    padding-bottom: 30px
  }

  .news .container {
    position: relative
  }

  .news .container .titling .text h2 {
    font-size: 24px;
    line-height: 110%;
    margin-bottom: 12px
  }

  .news .container .titling .text p {
    font-size: 14px;
    line-height: 130%
  }

  .news .container .titling .buttons {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0
  }

  .news .container .titling .buttons .news-swiper-button-next, .news .container .titling .buttons .news-swiper-button-prev {
    margin: 0;
    width: 40px;
    height: 40px;
    -webkit-background-size: 50% 50% !important;
    -moz-background-size: 50% 50% !important;
    -o-background-size: 50% 50% !important;
    background-size: 50% 50% !important
  }

  .news .container .news-swiper {
    width: 70%
  }

  .news .container .news-swiper .news-swiper-slide .item-content .category {
    font-size: 14px;
    margin-bottom: 8px
  }

  .news .container .news-swiper .news-swiper-slide .item-content .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px
  }

  .newspage {
    padding: 100px 0;
    padding-bottom: 30px
  }

  .newspage .titling {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -moz-box-orient: vertical;
    -moz-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
  }

  .newspage .titling .title {
    margin-bottom: 25px
  }

  .newspage .titling .img {
    width: 100%;
    margin: 0;
    padding-bottom: 50%
  }

  .newspage .titling:after {
    opacity: .2
  }

  .newspage .content a, .newspage .content blockquote, .newspage .content p {
    font-size: 16px;
    margin-bottom: 10px
  }

  .newspage .content ul {
    margin-bottom: 10px
  }

  .newspage .content ul li {
    font-size: 16px
  }

  .newspage .content table {
    margin-bottom: 10px
  }

  .newspage .content table td, .newspage .content table th {
    font-size: 14px;
    line-height: 1.2
  }
}

.feedback-swiper {
  cursor: pointer;
}
/* .loader{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: linear-gradient(192.04deg, #F61E2E 0%, #920448 100%);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */


.loader h4{
    color: #fff;
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 24px;
  }

  

  .spinner{
    width: 32px;
    height: 32px;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M12 2a1 1 0 00-1 1v2a1 1 0 002 0V3a1 1 0 00-1-1zm9 9h-2a1 1 0 000 2h2a1 1 0 000-2zM6 12a1 1 0 00-1-1H3a1 1 0 000 2h2a1 1 0 001-1zm.22-7a1.01 1.01 0 10-1.39 1.47l1.44 1.39a1 1 0 00.73.28 1 1 0 00.72-.31 1 1 0 000-1.41L6.22 5zM17 8.14a1 1 0 00.69-.28l1.44-1.39A1 1 0 0017.78 5l-1.44 1.42a1 1 0 000 1.41c.17.18.4.3.66.31zM12 18a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5.73-1.86a1 1 0 00-1.39 1.44L17.78 19a1 1 0 001.41-.02 1 1 0 000-1.42l-1.46-1.42zm-11.46 0l-1.44 1.39a1 1 0 00.33 1.64 1 1 0 00.39.08 1 1 0 00.67-.25l1.44-1.39a1 1 0 10-1.39-1.44v-.03z' /%3E%3C/svg%3E");
    background-size: 100%;
    background-repeat: no-repeat;
    animation-name: spinner;
    animation-duration: 1.1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    mask-size: 100%;
    mask-repeat: no-repeat;
    background: #1a279a;
    
  }



.hiding{
  opacity: 0;
  transition: all 1s linear;
}

@keyframes spinner {
  100%{
    transform: rotate(360deg);
  }
}

.text-title-nd {
  margin-bottom:30px;
}

.news-card-wrap {
  padding: 0;
  padding-bottom: 10px;
  border-radius: 20px;
}
.news-card-wrap  {
  margin-left: 10px;
}

.news-card__image {
  border: 1px solid rgba(0,0,0,.08);
}

.news-card__title{
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}
div.instant-view.single-content{
  width: 1px;
  height: 1px;
  overflow: hidden
}
.special_modal .success-content img  {
  width: 20%;
  margin-bottom: 20px!important;
}
.special_modal .success-content h3 {
  font-size: 27px!important;
  margin-bottom: 20px!important;
}
.special_modal .success-content .btn-defaultsqr {
  padding: 15px!important;
}
.special_modal .content {
  max-width: 600px!important;
  border-radius: 20px;
}
.special_modal .success-content {
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.card_loader rect {
  transform: scale(1.4);
}
 input {
   color: #000 !important;
 }


 .homeloader{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 }
 .video-modal-wrapper {
   margin-top: -10px;
   margin-bottom: -10px;
 }
 .review_swiper  .swiper-button-prev, .news_swiper  .swiper-button-prev {
   left: 89%!important;
 }
.review_swiper   .swiper-button-next ,.news_swiper   .swiper-button-next{
  right: -12px!important;
 }


  .homeloader h1{
    color: #fff;
    line-height: 140%;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 24px;
  }

  .homelogo{
    width: 114px;
    height: 150px;
    
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 80px;
  }

  .homespinner{
    width: 32px;
    height: 32px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M12 2a1 1 0 00-1 1v2a1 1 0 002 0V3a1 1 0 00-1-1zm9 9h-2a1 1 0 000 2h2a1 1 0 000-2zM6 12a1 1 0 00-1-1H3a1 1 0 000 2h2a1 1 0 001-1zm.22-7a1.01 1.01 0 10-1.39 1.47l1.44 1.39a1 1 0 00.73.28 1 1 0 00.72-.31 1 1 0 000-1.41L6.22 5zM17 8.14a1 1 0 00.69-.28l1.44-1.39A1 1 0 0017.78 5l-1.44 1.42a1 1 0 000 1.41c.17.18.4.3.66.31zM12 18a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5.73-1.86a1 1 0 00-1.39 1.44L17.78 19a1 1 0 001.41-.02 1 1 0 000-1.42l-1.46-1.42zm-11.46 0l-1.44 1.39a1 1 0 00.33 1.64 1 1 0 00.39.08 1 1 0 00.67-.25l1.44-1.39a1 1 0 10-1.39-1.44v-.03z' /%3E%3C/svg%3E");
    background-size: 100%;
    background-repeat: no-repeat;
    animation-name: spinner;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }





@keyframes spinner {
  100%{
    transform: rotate(360deg);
  }
}
.news_swiper {
    overflow: visible!important;
}
.news_swiper .swiper-slide {
  width: 363px!important;
  padding-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;
  height:310px;
}
.news_swiper .swiper-slide .item-content:hover .img img {
  transform: scale(1.07);
}
.news_swiper .swiper-slide .img  {
  width: 100%;
  position: relative;
  padding-bottom: 65%;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
  border: 1px solid rgba(0,0,0,.08);
}
.news_swiper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: .3s;
  position: absolute;
}
.news_swiper .swiper-slide .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}
.my_swiper .swiper-button-prev  {
    position: absolute;
    top: -40%;
    left: 87%;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 0 10px;
    background: url('./images/icons/arrow-slider-long-white.svg') no-repeat 50% #1a279a;
    flex-shrink: 0;
    transition: .3s;
    cursor: pointer;
}
.my_swiper .swiper-button-next  {
  position: absolute;
  top: 0;
  top: -40%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin: 0 10px;
  background: url('./images/icons/arrow-slider-long-white.svg') no-repeat 50% #1a279a;
  flex-shrink: 0;
  transition: .3s;
  cursor: pointer;
  transform: rotate(180deg);
}
.my_swiper .swiper-button-next:after {
  content: none!important;
}
.my_swiper .swiper-button-prev:after {
  content: none!important;
}
.my_swiper {
  overflow: visible!important;
}
.my_swiper .swiper-button-next,.my_swiper .swiper-button-prev  {
  top: -29%;
}
.my_swiper .feedback-swiper-slide {
  overflow: hidden;
}
.readyprograms-swiper .swiper-button-next {
  position: absolute;
  top: -50%;
  right: -1.6%;
  width: 45px;
  height: 35px;
  cursor: pointer;
  background: url('./images/icons/arrow-slider.svg') no-repeat 50%; 
  margin: 0 15px;
  transition: .3s;
  transform: rotate(180deg);
}
.readyprograms-swiper .swiper-button-prev {
  position: absolute;
  top: -50%;
  left: 88.5%;
  width: 45px;
  height: 35px;
  cursor: pointer;
  background: url('./images/icons/arrow-slider.svg') no-repeat 50%; 
  margin: 0 15px;
  transition: .3s;
}
.readyprograms-swiper .swiper-button-prev:after,.readyprograms-swiper .swiper-button-next:after {
  content: none!important;
}
.readyprograms-swiper {
  overflow: visible!important;
}
.readyprograms-swiper .swiper-button-prev::before {
  position: absolute;
  left: 130%;
  width: 2px;
  height: 20px;
  content: "";
  background-color: rgb(0, 0, 0);
  z-index: 3;
}
.readyprograms-swiper .swiper-slide  {
  overflow: hidden;
  border-radius: 10px;
}
.readyprograms .container .installapp-btn  {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width:767px) {
  .container  {
    width: 100vw;
    margin: auto;
    padding: 0 15px;
  }
  .modal .content .text-content {
    height: auto!important;
  }
  header {
    width: 100vw;
  }
  .feedbacks .text p  {
    width: 80vw;
    white-space: pre-wrap;
  }
  .applicatoins-photo {
    flex-direction: column;
    align-items: center;
  }
  .applicatoins-photo .item {
    width: 100%!important;
  }
  input {
    color: #fff!important;
  }
  .news_swiper .swiper-slide  {
   width: 100%!important;
  }
  
  .news_swiper .swiper-slide .img, .news_swiper .swiper-slide img{
    width: 100%!important;
    object-fit: cover;
  }
  .news_swiper .swiper-slide .title {
    margin-top: 10px;
  }
  
  .review_swiper .swiper-button-next {
    right: 4.5%!important;
  }
  .applicatoins-photo.swiper-wrapper {
    overflow: hidden!important;
  }
  .news_swiper .swiper-button-next {
    right: -1%!important;
  }
  .my_swiper .swiper-button-next,  .my_swiper .swiper-button-prev {
    top: 105%;
  }
  .readyprograms-swiper  .swiper-button-next,  .readyprograms-swiper .swiper-button-prev {
    top: 108%;
  }
 
  .readyprograms-swiper .swiper-button-prev {
    left: -5%;
  }
  .readyprograms-swiper .swiper-button-next {
    right: -4.6%;
  }
  .readyprograms-swiper .swiper-button-prev::before {
    display: none;
 }
 .readyprograms .container .installapp-btn {
   margin-top: 100px;
 }
  .my_swiper .swiper-button-prev {
    left: 0;
  }
  .news .container  {
    padding-bottom: 75px;
  }
  .news {
    margin-top: 30px;
  }
  .additionals .install{
    display: none;
  }
  
   .links {
     display: none;
   }
  
  .btn-defaultsqr  {
    display: flex!important;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
  .introduction {
    margin-top: 60px;
  }
  .modal .holder .content .modal-close  {
    right: 13%;
    top: 6.5%;
    mask-image: url('./images/icons/close.svg');
    mask-size: 100%;
    mask-repeat: none;
    background: #1a279a;
  }
  .special_modal  .holder .content .modal-close  {
    top: 8.5%;
  }
  .modal .holder .content .btn-defaultsqr.loading {
    color: transparent!important;
  }
 
  .homeloader {
    width: 100vw;
    overflow: hidden;
  }
  .homeloader h1 {
    font-size: 22px;
  }
  .modal  {
    width: 100vw;
  }
  .newspage {
    margin-top: 70px;
    padding: 10px;
  }
  .newspage .content  p {
    font-size: 18px!important;
  }
  .newspage h1 {
    font-size: 26px!important;
    color:#000
  }
  .news-list {
    flex-direction: column;
  }
  .news-card-wrap {
    width: 100%!important;
  }
  .post_page {
    margin-top: 70px;
  }
  .feedbacks .swiper-wrapper {
    width: 100vw;
  }
  
  .feedbacks {
    padding-bottom: 80px;
    overflow: hidden!important;
  }
  .feedback-swiper-slide p {
    max-height: 360px;
    overflow: hidden;
  }
  .modal .content {
    padding: 20px;
    max-height: 92vh!important;
    overflow-y: scroll;
    box-sizing: border-box;
  }
  .modal .holder {
    padding: 50px 15px;
    box-sizing: border-box;
  }
  .modal .content .text-content {
    padding-bottom: 40px;
  }
  .modal .content::-webkit-scrollbar-track {
    background: transparent!important;
    border-radius: 10px;
}
.modal .content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(31, 0, 67);
}
.modal .content::-webkit-scrollbar {
   width: 3px;
   background: transparent!important;
}
.modal input {
  color:#000!important
}
.modal .content .form a {
  display: block;
  padding-bottom: 40px;
}
.form-submit:after {
  background-color: #1a279a!important;
  top: 45%!important;
}
.leaveinfo  .btn-defaultsqr:after ,footer .btn-defaultsqr:after{
  background-color: #1a279a!important;
  top: 45%!important;
}
.post_page .content_loader{
  margin-top: -50px;
}
.btn-defaultsqr  {
  min-height: 53px!important;
  max-height: 53px!important;
}
button.loading {
 color: #fff!important;
}
.btn-defaultsqr:hover {
  opacity: 1!important;
}

.spinner {
  margin-bottom: 45px;
}
.review_swiper .swiper-button-prev,.news_swiper .swiper-button-prev {
  left: -1%!important
}
.video_modal .content {
  margin-top: 100px;
  padding: 0!important;
  background-color: transparent!important;
  overflow-y: visible;
}
.video_modal .content .modal-close {
  top: -10%!important;
  background: #fff!important;
}
}