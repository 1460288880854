.display-inline {
  display: inline-block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.feedbacks .swiper-container {
  overflow: initial !important;
}
.feedbacks {
  height: auto;
  padding-top: 160px;
}

.feedbacks .swiper-slide:not(.swiper-slide-active) .img {
  opacity: 0 !important;
  transition: 0.3s ease-in-out;
}

.feedbacks .swiper-slide:not(.swiper-slide-active) .text .titling {
  opacity: 0 !important;
  transition: 0.5s ease-in-out;
  transform: translateY(-10px);
}
.feedbacks .swiper-slide:not(.swiper-slide-active) .text p {
  opacity: 0 !important;
  transition: 0.5s ease-in-out;
  transform: translateY(-10px);
}
.feedbacks .container .feedback-swiper .feedback-swiper-slide .img:before {
  right: 0;
}
.feedbacks .container .feedback-swiper .feedback-swiper-slide .img img {
  max-height: 430px;
  object-fit: cover;
}
.eachSlide {
  width: 100%;
}
.feedbacks .container .feedback-swiper .feedback-swiper-slide .text {
  min-height: 325px;
}

.page-content {
  overflow: hidden;
}

.content-style img {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.__nuxt-error-page {
  z-index: 10;
}

.modal .holder .content .desc-content h3 {
  font-size: 34px;
}

.modal .holder .content .desc-content {
  justify-content: flex-start;
}
.text-content p {
  margin: 24px 0;
}
.text-content p:first-child {
  margin-top: 0;
}
.text-content p:last-child {
  margin-bottom: 0;
}
.text-content ul li {
  list-style: disc;
  margin: 10px 0;
  font-weight: 700;
}
.text-content ul {
  font-size: 18px;
  margin: 24px 0 24px 18px;
}
.offers .container .cards_row .card .card-content {
  cursor: pointer;
}
.modal {
  transition: 0.3s ease-in-out;
}
.modal .content {
  transform: scale(0.8);
  pointer-events: none;
  transition: 0.3s ease-in-out;
}
.modal.show .content {
  transform: scale(1);
  pointer-events: auto;
}

.news-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.news-list .news-card-wrap {
  width: 32%;
  margin-bottom: 30px;
}

.news-card {
  display: block;
}
.loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-card__image {
  width: 100%;
  position: relative;
  padding-bottom: 65%;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
}
.news-card__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 0.3s;
  position: absolute;
}

header.--hidden {
  transform: translateY(-100%);
}

.feedbacks .container h2 {
  margin-right: 70px;
}

.btn-defaultsqr,
.form-submit {
  position: relative;
}
.btn-defaultsqr.loading:after,
.form-submit.loading:after {
  opacity: 1;
}
.btn-defaultsqr.loading,
.form-submit.loading {
  color: transparent;
}
.btn-defaultsqr:after,
.form-submit:after {
  opacity: 0;
  pointer-events: none;
  margin-left: -15px;
  margin-top: -15px;
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  width: 32px;
  height: 32px;
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M12 2a1 1 0 00-1 1v2a1 1 0 002 0V3a1 1 0 00-1-1zm9 9h-2a1 1 0 000 2h2a1 1 0 000-2zM6 12a1 1 0 00-1-1H3a1 1 0 000 2h2a1 1 0 001-1zm.22-7a1.01 1.01 0 10-1.39 1.47l1.44 1.39a1 1 0 00.73.28 1 1 0 00.72-.31 1 1 0 000-1.41L6.22 5zM17 8.14a1 1 0 00.69-.28l1.44-1.39A1 1 0 0017.78 5l-1.44 1.42a1 1 0 000 1.41c.17.18.4.3.66.31zM12 18a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm5.73-1.86a1 1 0 00-1.39 1.44L17.78 19a1 1 0 001.41-.02 1 1 0 000-1.42l-1.46-1.42zm-11.46 0l-1.44 1.39a1 1 0 00.33 1.64 1 1 0 00.39.08 1 1 0 00.67-.25l1.44-1.39a1 1 0 10-1.39-1.44v-.03z' /%3E%3C/svg%3E");
  background-size: 100%;
  background-repeat: no-repeat;
  animation-name: spinner;
  animation-duration: 1.1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  mask-size: 100%;
  mask-repeat: no-repeat;
  background: rgb(255, 255, 255);
}

.form-submit:after {
  border: 3px solid rgba(26, 39, 154, 0.3) !important;
  border-top-color: #1a279a !important;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.introduction {
  min-height: 900px;
}
.br-15 {
  border-radius: 15px;
}
.video-modal-wrapper {
  padding-bottom: 60%;
  width: 100%;
  position: relative;
}
.video-modal-wrapper iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 15px;
}

.contactus h2 {
  margin-left: 100px;
  max-width: 700px;
  text-align: center;
}
.contactus {
  overflow: visible;
}

@media (max-width: 992px) {
  .contactus h2 {
    margin-left: 0;
  }
  .offers .container .cards_row {
    padding-left: 0;
  }
  .introduction {
    min-height: auto;
  }
}

@media (max-width: 768px) {
  .feedbacks .container h2 {
    margin-right: 0;
    width: auto;
    align-self: flex-start;
  }
  .applications .container .applicatoins-photo .item {
    width: 200px;
    min-width: 200px;
  }
  .applications .container .applicatoins-photo {
    overflow-x: auto;
  }
}

@media (max-width: 576px) {
  .readyprograms .container .readyprograms-swiper {
    width: 100% !important;
  }
  .feedbacks {
    padding-top: 30px;
  }
  .news .container .news-swiper {
    width: 100%;
  }
  .news .container .titling .buttons {
    top: 50%;
  }
  header .navigation .additionals .lang {
    margin-right: 0;
  }
  header .navigation .additionals .lang a {
    width: 40px;
    height: 40px;
    font-size: 14px;
    margin: 0;
    margin-left: 10px;
  }
}

.newspage .content a,
.newspage .content blockquote,
.newspage .content p {
  display: inline-block;
}
.newspage .content a {
  padding: 0;
  margin: 0;
  color: #03a9f4;
}

footer .outro-content .container .socials a img {
  width: 24px;
}

.modal--overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  content: "";
}

.btn-default,
.btn-defaultsqr {
  border-radius: 10px !important;
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide .img img {
  object-position: top;
}

.applications .container .applicatoins-photo .item {
  cursor: pointer;
}

.applications .container .applicatoins-photo .item {
  opacity: 0.6;
  transition: 0.3s ease-in-out;
}

.applications .container .applicatoins-photo .item.active {
  opacity: 1;
}

.applications .container .applicatoins-photo .item .image {
  transform: scale(0.8);
  transition: 0.3s ease-in-out;
}
.applications .container .applicatoins-photo .item.active .image {
  transform: scale(1);
}
.applications
  .container
  .applicatoins-photo
  .item:first-child
  .image
  .image-item:after {
  display: none;
}
.applications .container .applicatoins-photo .item h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.introduction .content-main .container .inner-content .intro-makecall p,
.introduction .content-main .container .inner-content .intro-makecall p span {
  font-family: sans-serif;
}

footer .container .under-content .content .phone .makecall p,
footer .container .under-content .content .phone .makecall p span {
  font-family: sans-serif;
}

.btn-defaultsqr {
  font-size: 16px;
}
.contactus h2 {
  font-size: 20px;
}

.resulsteps .container .items_row .item .item-content p {
  font-weight: 500;
  font-size: 18px;
}
.news .container .titling {
  margin-bottom: 40px;
}
.news {
  padding: 50px 0 80px;
}
.news .container .news-swiper-slide .item-content .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}
.news .container .news-swiper-slide .item-content .img {
  margin-bottom: 20px;
}
.newspage .titling .img img {
  border-radius: 10px;
}

.feedbacks .container .feedback-swiper .feedback-swiper-slide {
  opacity: 0 !important;
}
.feedbacks
  .container
  .feedback-swiper
  .feedback-swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}
.feedbacks .container .feedback-swiper .feedback-swiper-slide .text {
  min-height: 397px;
}

#root {
  overflow: hidden;
}

@keyframes anima {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.skeleton__single-news {
  animation: anima 1.5s infinite;
}

.single-news__top {
  display: flex;
}
.single-news__title-wrapper {
  width: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.single-news__img {
  width: 361px;
  height: 235px;
  background: linear-gradient(90deg, #f5f5f5 8%, #efefef 18%, #f5f5f5 33%);
  overflow: hidden;
  flex: 0 0 auto;
  border-radius: 15px;
}
.single-news__date {
  height: 60px;
}
.single-news__date--content {
  width: 60px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f5f5f5 8%, #efefef 18%, #f5f5f5 33%);
}
.single-news__title {
  height: 20px;
  margin: 15px 0;
  border-radius: 5px;
  width: 87%;
  background: linear-gradient(90deg, #f5f5f5 8%, #efefef 18%, #f5f5f5 33%);
  opacity: 0.5;
}
.single-news__title:last-child {
  margin-bottom: 0px;
}
.single-news__title {
  width: 361px;
}
.skeleton__single-news.single {
  display: flex;
}
.single_bottom {
  margin-top: 100px;
}
.single_bottom .single-news__title {
  width: 500px;
}
.skeleton_news_title {
  display: none;
}
@media screen and (max-width: 767px) {
  .loader_wrapper {
    display: flex;
    flex-direction: column;
  }
  .skeleton__single-news {
    width: 90vw;
    margin: auto;
    margin-bottom: 40px;
  }
  .skeleton__single-news.single {
    display: flex;
    flex-direction: column;
  }
  .single_bottom {
    margin: 0;
  }
  .single-news__title {
    width: 100% !important;
  }
  .single-news__img {
    width: 100% !important;
  }
  .skeleton_news_title {
    display: block;
    height: 30px;
    border-radius: 5px;
  }
}

.partners {
  margin-top: 120px;
  padding: 20px 0;
}

.partners__title {
  margin-top: 0;
  margin-bottom: 60px;
  text-align: center;
}

.partners__list {
  width: calc(340px * 12);  
  display: flex;
  padding: 5px 0;
  align-items: stretch;
  justify-content: space-around;
  height: 100%;
}

.partners__item {
  width: 340px;
  height: 135px;
  padding: 26px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(169, 169, 169, 0.3);
  border-radius: 20px;
  animation: translatestf 30s linear infinite;
}

.partners__img {
  object-fit: contain;
}

.partners__slider {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
}

.highway-barrier {
  overflow: hidden;
  position: relative;
}

@keyframes translatestf {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-500%);
  }
}

@keyframes translateinfinite {
  100% {
    transform: translateX(calc(-185px * 12));
  }
}

.highway-barrier::before,
.highway-barrier::after {
  content: " ";
  position: absolute;
  z-index: 9;
  width: 180px;
  height: 100%;
}

.highway-barrier::before {
  top: 0;
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.highway-barrier::after {
  top: 0;
  right: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.partners__item {
  animation: translateinfinite 25s linear infinite;
}
