@font-face {
  font-family: Manrope;
  src: url('./fonts/Raleway-Bold.woff') format("woff2");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: Manrope;
  src: url('./fonts/Raleway-SemiBold.woff') format("woff2");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: Manrope;
  src: url('./fonts/Raleway-Medium.woff') format("woff2");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: Manrope;
  src: url('./fonts/Raleway-Regular.woff') format("woff2");
  font-weight: 300;
  font-style: normal
}
